export const SUPERUSER_IDS = [
  // production - log-and-solve
  "FHKkMJX12dZ4krDBjRFH0ed3yYi1", // co-anne.vanwaaij@klasmann-deilmann.com
  "Onpuei1jO8aLLlLLB8NADoz4aMX2", // gerard.flinterman@klasmann-deilmann.com
  "rGsYZXd2xoOuJGzrW40JWfpiIfL2", // tess.vollebregt@klasmann-deilmann.com
  "fdbQscgk8XP6JkeUOAIcI1HEgUx2", // rick.vanmarrewijk@klasmann-deilmann.com
  "aI3vnNdA0Pg3Nuf5BOvH86IaKRh1", // miklebel97@gmail.com
  "RFPZpPE2vgMJ1kcj6kk1o1KH5Z43", // eelke@aimforthemoon.com
  "6N8H0H64AmUcVryYlF8Ypcc4AaD3", // m.froberg@30mhz.com
  // staging - log-and-solve-staging-7a93b
  "6OFLVOxbt4RRcQu5MsGAkSiiLdH3", // miklebel97@gmail.com
  "7ISRss7jtPUsRJYZzqLYLzzgY3D3", // d.smits@30mhz.com
  "UCTQujLlwqXsKrgnHQOquIopsB03", // m.froberg@30mhz.com
  "A0RmuUW5ltM4c9KlujyZnmOCSGf2", // f.mikaelian@30mhz.com
];
