import { computed } from "vue";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

interface SettingsSchema {
  viewCompanyId: string | null;
  cropTable: {
    sortBy: string;
    sortDirection: "desc" | "asc";
    genus?: string;
    cultivar?: string;
    potDate?: [string, string];
    species?: string;
    isTrialCrop: boolean;
    showProgressBar: boolean;
    showColumns: { [key in Columns]: boolean };
    columnOrder: { [key in Columns]: number };
    advancedFilters?: {
      criteria: any[];
      active: boolean;
      matches: any;
    };
  };
  cropOverviewFilters: Record<string, string[]>;
  dataExplorerFilters: Record<string, string[]>;
  showHectares: boolean;
  selectCropOption: {
    secondRow: Columns[];
    thirdRow: Columns[];
    sort: {
      column: Columns;
      direction: "asc" | "desc";
    };
  };
}

export enum Columns {
  ICON = "icon",
  GENUS = "genus",
  SPECIES = "species",
  CULTIVAR = "cultivar",
  CONTAINER = "container",
  LAB_ID = "labDataId",
  UPDATES = "updates",
  PROGRESS = "progress",
  TRAYS_AMOUNT = "traysAmount",
  TRAY_CELLS_AMOUNT = "trayCellsAmount",
  STARTING_AMOUNT = "startingNumberOfPots",
  CURRENT_AMOUNT = "currentNumberOfPots",
  YOUNG_PLANT_SUPPLIERS = "youngPlantSuppliers",
  BUSINESS_LOCATION = "businessLocation",
  LOCATION_LEVEL_1 = "locationLevel1",
  LOCATION_LEVEL_2 = "locationLevel2",
  WEEK_NR = "weekNr",
  BATCH_NUMBER = "batchNumber",
  BULB_SIZE = "bulbSize",
  PERSON_PLANTED = "personPlanted",
  BARCODE = "barcode",
  ALERTS = "alerts",
  SIZE_DESCRIPTION = "sizeDescription",
  CROP_LABEL = "cropLabel",
  SUBSTRATE_INFO = "substrateInfo",
  REFERENCE_GENUS = "referenceGenus",
  PHASE = "phase",
  DRIPPERS = "drippers",
  SPACING_DATE = "spacingDate",
  POT_DATE = "potDate",
  END_DATE = "endDate",
  EXPECTED_CULTIVATION_TIME = "expectedCultivationTime",
  DESCRIPTION = "description",
  OBJECTIVES = "objectives",
  PLANTS_PER_CONTAINER = "plantsPerContainer",
  NUMBER_OF_CONTAINERS = "numberOfContainers",
  SUPPLIER_DOCUMENT_NR = "supplierDocumentNr",
  CONTAINER_NR = "containerNr",
  SUBSTRATE_MIXTURE = "substrateMixture",
  FERTILIZATION = "fertilization",
}

const defaultValues: SettingsSchema = {
  viewCompanyId: null,
  cropTable: {
    sortBy: "alerts",
    sortDirection: "desc",
    isTrialCrop: false,
    showProgressBar: true,
    showColumns: Object.values(Columns).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {} as { [key in Columns]: boolean },
    ),
    columnOrder: Object.values(Columns).reduce(
      (acc, key, index) => {
        acc[key] = index;
        return acc;
      },
      {} as { [key in Columns]: number },
    ),
  },
  selectCropOption: {
    secondRow: [Columns.CONTAINER, Columns.WEEK_NR],
    thirdRow: [
      Columns.BUSINESS_LOCATION,
      Columns.LOCATION_LEVEL_1,
      Columns.LOCATION_LEVEL_2,
    ],
    sort: {
      column: Columns.GENUS,
      direction: "asc",
    },
  },
  dataExplorerFilters: {},
  cropOverviewFilters: {},
  showHectares: false,
};

export const useSettingsStore = defineStore("settings", () => {
  const viewCompanyId = useLocalStorage<SettingsSchema["viewCompanyId"]>(
    "settings.viewCompanyId",
    defaultValues.viewCompanyId,
    { mergeDefaults: true },
  );

  const cropTableSettings = useLocalStorage<SettingsSchema["cropTable"]>(
    "settings.cropTable",
    defaultValues.cropTable,
    {
      mergeDefaults: true,
    },
  );

  const selectCropOption = useLocalStorage<SettingsSchema["selectCropOption"]>(
    "settings.selectCropOption",
    defaultValues.selectCropOption,
    {
      mergeDefaults: true,
    },
  );

  const cropOverviewFiltersSettings = useLocalStorage<
    SettingsSchema["cropOverviewFilters"]
  >("settings.cropOverviewFilters", defaultValues.cropOverviewFilters, {
    mergeDefaults: true,
  });

  const dataExplorerFiltersSettings = useLocalStorage<
    SettingsSchema["dataExplorerFilters"]
  >("settings.dataExplorerFilters", defaultValues.dataExplorerFilters, {
    mergeDefaults: true,
  });

  const showHectares = useLocalStorage<SettingsSchema["showHectares"]>(
    "settings.showHectares",
    defaultValues.showHectares,
    {
      mergeDefaults: true,
    },
  );

  const tableColumns = computed({
    get: () => {
      const entries = Object.entries(cropTableSettings.value.showColumns);
      const missingColumns = Object.values(Columns).filter(
        (column) => !entries.some(([key]) => key === column),
      );
      for (const missingColumn of missingColumns) {
        entries.push([missingColumn, false]);
      }
      return Object.fromEntries(entries) as { [key in Columns]: boolean };
    },
    set: (value) => {
      return (cropTableSettings.value.showColumns = value);
    },
  });

  const tableOrderColumns = computed({
    get: () => {
      const entries = Object.entries(cropTableSettings.value.columnOrder);
      const missingColumns = Object.values(Columns).filter(
        (column) => !entries.some(([key]) => key === column),
      );
      for (const missingColumn of missingColumns) {
        entries.push([missingColumn, entries.length]);
      }
      return Object.fromEntries(entries) as { [key in Columns]: number };
    },
    set: (value) => {
      return (cropTableSettings.value.columnOrder = value);
    },
  });

  const cropOverviewFilters = computed({
    get: () => {
      return cropOverviewFiltersSettings.value;
    },
    set: (value) => {
      return (cropOverviewFiltersSettings.value = value);
    },
  });

  const dataExplorerFilters = computed({
    get: () => {
      return dataExplorerFiltersSettings.value;
    },
    set: (value) => {
      return (dataExplorerFiltersSettings.value = value);
    },
  });

  return {
    tableColumns,
    viewCompanyId,
    tableOrderColumns,
    cropOverviewFilters,
    dataExplorerFilters,
    showHectares,
    selectCropOption,
  };
});
